html,
body {
    min-width: 320px;
    min-height: 100vh;
}

.no-scroll {
    position: relative;
    overflow: hidden;
}

img {
    will-change: opacity, height, transform;
}
